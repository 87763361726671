import React from 'react';
import photo from './Birds-in-the-Sky.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={photo} alt="birds-in-the-sky" />
        <p>
          This is a playground for me to improve.
        </p>
      </header>
    </div>
  );
}

export default App;
